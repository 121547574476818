/**
 * Helpers
 */
import { fromBreakpoint, onVisibilityChange, toBreakpoint } from './base/helpers';

/**
 * Web standards
 */
import './base/cookieconsent';
import './base/softscroll';
import './base/lazyload';
import 'alpinejs/dist/cdn.min.js';
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'
 
import { modal } from './base/modal';


Alpine.plugin(collapse)
Alpine.plugin(intersect)

/**
 * Scripts which don't need dynamic import
 */
let wpcf7forms = document.querySelector('.wpcf7-form');
if (wpcf7forms) {
	const jquery = import('jquery');
	global.jQuery = require('jquery');
	window.jQuery = require('jquery');
}



/**
 * Dynamic import scripts
 */
const elements = [
	'[data-ajax-filter-container]',
	'[data-aos]',
	'[data-modal-target]',
	'.splide',
  ];
  
  [].forEach.call(elements, (element) => {
	if (element !== '') {
	  if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {
		const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

  
			if (element === '[data-ajax-filter-container]') {
				const ajaxFilter = import('./theme/ajax-filter');
			  }
			  
			  if (element === '[data-aos]') {
				const AOS = import('aos').then(AOS => {
				  AOS.init({
					disable: function() {
					  return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
					}
				  });
				});
			  }
  
			  if (element === '[data-modal-target]') {
				modal.init();
			  }
			  if(element === '.splide'){
				const splide = import('./vendor/splide');
			  }
  
		})
  
		if (window.addEventListener) {
		  addEventListener('DOMContentLoaded', lazyloadHandler, false);
		  addEventListener('load', lazyloadHandler, false);
		  addEventListener('scroll', lazyloadHandler, false);
		  addEventListener('resize', lazyloadHandler, false);
		} else if (window.attachEvent) {
		  attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
		  attachEvent('onload', lazyloadHandler);
		  attachEvent('onscroll', lazyloadHandler);
		  attachEvent('onresize', lazyloadHandler);
		}
	  }
	}
  });